// Fade
.g-fade-enter-active,
.g-fade-leave-active {
  transition: opacity 0.5s;
}

.g-fade-enter, .g-fade-leave-to {
  opacity: 0;
}

// Fades in and slides up a bit
.g-slide-up-enter-active {
  transition: opacity 1s ease, transform 1s ease;
}

.g-slide-up-enter {
  transform: translateY(10px);
  opacity: 0;
}

// Fades in and slides from right
.g-slide-right-enter-active,
.g-slide-right-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.g-slide-right-enter,
.g-slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
