
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































$bp: 'm';

.header-primary__list {
  @extend %list-nostyle;

  @include mq($bp) {
    display: flex;
    flex-wrap: wrap;
  }
}

.header-primary__item {
  position: relative;
  padding: 1rem 0;

  @include mq($bp) {
    width: 50%;
    padding: 1.5rem 0;

    &:nth-child(1),
    &:nth-child(2) {
      padding-top: 0;
    }

    &:nth-child(2n + 1) {
      padding-right: 1rem;
    }

    &:nth-child(2n) {
      padding-left: 1rem;
    }
  }

  @include mq(l) {
    &:nth-child(2n + 1) {
      padding-right: 3rem;
    }

    &:nth-child(2n) {
      padding-left: 3rem;
    }
  }
}

.header-primary__item__link {
  @extend %ff-alt;
  @extend %fw-bold;

  position: static;
  display: block;
  font-size: 2.5rem;
  line-height: 1;
  text-decoration: none;
  transition: color 0.2s $ease-softer;

  &:hover,
  &:focus-visible {
    color: $c-mustard;
  }

  &::after {
    @include get-all-space;

    content: '';
  }

  @include mq($bp) {
    font-size: 3rem;
    line-height: 4rem;
  }
}

.header-primary__item__text {
  @include mq($until: $bp) {
    display: none;
  }
}
