
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































































.flexible-video {
  position: relative;
  /* stylelint-disable-next-line declaration-no-important */
  margin-top: 3rem !important;
}

.flexible-video__picture,
.flexible-video__video {
  @extend %border-radius-small;

  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 85vh;

  video {
    @extend %border-radius-small;
    @include image-fit;
  }
}

.flexible-video__video {
  height: auto;
  aspect-ratio: 3/2;
}

.flexible-video__button {
  @extend %button-nostyle;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $c-white;
  text-align: center;

  @include mq($until: 's') {
    @include get-all-space;
  }

  @include mq(s) {
    @include center-xy;

    width: 15rem;
    height: 15rem;
    padding: 3rem;

    &::after {
      @include get-all-space;

      content: '';
      z-index: -1;
      background: $c-mustard;
      border-radius: 50%;
      transition: 0.2s ease-out;
    }

    &:hover::after {
      transform: scale(1.1);
    }
  }

  @include mq(l) {
    width: 20rem;
    height: 20rem;
    padding: 2rem;
  }
}

.flexible-video__button__icon {
  display: block;
  padding: 1rem;
  background: $c-mustard;
  border-radius: 50%;
  transition: transform 0.2s ease-out;

  .icon {
    width: 3rem;
    height: 3rem;
  }

  .flexible-video__button:hover & {
    transform: scale(1.2);
  }

  @include mq(s) {
    padding: 0;
    background: 0;

    .icon {
      width: 4rem;
      height: 4rem;
    }

    .flexible-video__button:hover & {
      transform: scale(1);
    }
  }

  @include mq(l) {
    .icon {
      width: 6rem;
      height: 6rem;
    }
  }
}

.flexible-video__button__label {
  @extend %ff-alt;

  display: none;
  line-height: 1;

  @include mq(s) {
    display: block;
  }

  @include mq(l) {
    font-size: 2.5rem;
  }
}

::v-deep {
  .picture__container {
    height: 0;
    padding-bottom: 66.6%;
  }

  .picture__caption {
    display: none;
  }
}
