
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































































































































































































































$bp: 'm';

.header-menu {
  @include scrollbars(6px, $c-mustard, $c-white);

  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background: $c-white;
}

.header-menu-inner {
  margin-top: 9rem;
  padding-bottom: $spacing;

  @include mq($bp) {
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 1fr 1fr var(--cta-col);
    grid-gap: 0 $spacing * 1.5;
    margin-top: 13rem;
  }

  @include mq(wrapper) {
    grid-gap: 0 $spacing * 3;
  }
}

.header-menu__lang {
  @extend %list-nostyle;
  @extend %text-uppercase;
  @extend %fw-medium;
  @extend %ff-alt;

  display: flex;
  justify-content: flex-start;
  margin-bottom: $spacing * 0.5;

  @include mq($bp) {
    display: none;
  }
}

.header-menu__lang__item {
  & + & {
    margin-left: $spacing;
  }

  a {
    display: block;
    padding: 1rem 0;
    text-decoration: none;
  }
}

.header-menu__primary {
  @include mq($bp) {
    grid-row: 1/2;
    grid-column: 1/3;
  }
}

.header-menu__secondary {
  @include mq($bp) {
    grid-row: 2/4;
    grid-column: 1/2;
  }
}

.header-menu__ctas {
  --cta-padding: #{$spacing * 1.5};
  --cta-arrow-width: 4rem;

  @include mq($bp) {
    grid-row: 1/2;
    grid-column: 3/4;
  }

  @include mq(l) {
    --cta-arrow-width: 5rem;
  }

  @include mq($from: xl, $and: '(min-height: #{$menu-desktop-height})') {
    --cta-arrow-width: 6rem;
  }
}

.header-menu__socials {
  ::v-deep .socials__link:not(:hover):not(:focus-visible) {
    color: $c-dark-grey;
  }

  @include mq($bp) {
    justify-content: flex-end;
    grid-row: 2/3;
    grid-column: 2/4;
    align-self: end;
    padding-bottom: $spacing * 0.5;
  }
}

.header-menu__legal {
  @include mq($bp) {
    grid-row: 3/4;
    grid-column: 2/4;
    align-self: end;
    padding-bottom: $spacing * 0.25;
  }
}
