
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































$icon-margin: 1rem;
$easing: 0.75s $ease-80-20;

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.sending {
  pointer-events: none;
}

// Defaults
.action,
[class*='action--'] {
  color: $c-dark-grey;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

// Links
[class*='action--'][class*='--link'] {
  font-size: 14px;
  line-height: 20px;
  transition: color $easing;

  @include mq(l) {
    &:hover {
      color: $c-mustard;
    }
  }
}

[class*='action--'][class*='--link'][class*='--colored'] {
  color: $c-mustard;

  @include mq(l) {
    &:hover {
      color: $c-gray-light;
    }
  }
}

[class*='action--'][class*='--link'][class*='--uppercase'] {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--btn'] {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      xxs: 14px,
      xxl: 18px,
    )
  );

  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: fit-content;
  padding: 1rem 2rem;
  text-align: center;
  background: transparent;
  border: 1px solid $c-mustard;
  border-radius: 3rem;
  transition: background $easing;

  &:focus-visible {
    background: rgba($c-mustard, 0.2);
  }

  &[disabled] {
    color: $c-light-grey;
    border-color: $c-light-grey;
  }

  @include mq(m) {
    padding: 1rem 3rem;
  }

  @include mq(l) {
    &:hover {
      color: $c-white;
      background: $c-mustard;
    }
  }
}

[class*='action--'][class*='--btn'][class*='--filled'] {
  color: $c-white;
  background: $c-mustard;
  transition: background $easing, border-color $easing;

  /* stylelint-disable-next-line selector-max-attribute */
  &[disabled] {
    border-color: $c-mustard;
  }

  @include mq(l) {
    &:hover,
    &:focus-visible {
      color: $c-dark-grey;
      background: rgba($c-mustard, 0);

      .flexible-experts__item & {
        background: $c-white;
        border-color: $c-white;
      }
    }
  }

  @include mq(l) {
    .header.white:not(.down):not(.menu-open) &,
    .homepage .header:not(.menu-open):not(.down) &:hover,
    .magazine-landing .header:not(.menu-open):not(.down) &:hover {
      color: $c-white;
    }
  }
}

[class*='action--'][class*='--reverse'] {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
}

[class*='action--'][class*='--no-label'] {
  padding: 0 3rem;
}

[class*='action--'][class*='--light'] {
  padding: 0;
  border: 0;

  &[class*='--uppercase'] {
    @extend %text-uppercase;
    @extend %ff-default;
    @extend %fw-bold;

    font-size: 1.2rem;
    line-height: 2.5rem;
  }

  @include mq(l) {
    &:hover,
    &:focus-visible {
      color: $c-mustard;
      background: 0;
    }
  }
}

[class*='action--'][class*='--social'] {
  position: relative;
  display: inline-block;
  overflow: visible;
  width: 4rem;
  height: 4rem;
  padding: 0;
  border: 0;

  &::before,
  &::after {
    @include get-all-space;

    content: '';
    z-index: -1;
    border: 1px solid $c-light-grey;
    border-radius: 50%;
    transform: scale(1);
    transition: transform $easing;
  }

  &::after {
    @include get-all-space;

    background: rgba($c-light-grey, 0.25);
    border: 0;
    opacity: 0;
    transform: scale(0.2);
    transition: all $easing;
  }

  &:focus-visible {
    background: rgba($c-light-grey, 0.5);
  }

  @include mq(l) {
    &:hover {
      color: $c-dark-grey;
      background: 0;

      &::before {
        transform: scale(1.1);
        transition: transform $easing;
      }

      &::after {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
}

[class*='action--'][class*='--full-width'] {
  justify-content: center;
  width: 100%;
}

[class*='action--'][class*='--light'][class*='--btn'] {
  @extend %ff-default;
  @extend %fw-normal;

  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  border-radius: 0;
}

.action__label {
  .sending & {
    display: none;
  }

  [class*='action--'][class*='--btn'] & {
    position: relative;
    transition: $easing;

    &::before {
      @include center-x;

      content: attr(data-text);
      width: 100%;
      margin-top: $icon-margin;
      color: $c-white;
      opacity: 0;
      transition: $easing;
    }
  }

  [class*='action--'][class*='--light'] & {
    &::before {
      content: none;
    }
  }

  [class*='action--'][class*='--read-more'] & {
    display: inline-block;
    opacity: 0;
    transform: translateX(1rem);
    transition: opacity 0.1s, transform 0.2s;
  }

  [class*='action--'][class*='--no-label'] &,
  [class*='action--'][class*='--no-label'][class*='--btn'] & {
    @extend %visually-hidden;
  }

  @include mq(l) {
    [class*='action--'][class*='--btn']:hover & {
      color: rgba($c-mustard, 0);
      transform: translate3d(0, -$icon-margin, 0);

      &::before {
        opacity: 1;
      }
    }

    [class*='action--'][class*='--light']:hover & {
      color: $c-mustard;
      transform: none;
    }

    [class*='action--'][class*='--read-more']:hover &,
    [class*='action--'][class*='--read-more']:focus-visible & {
      opacity: 1;
      transform: translateX(0);
    }

    [class*='action--'][class*='--btn'][class*='--filled']:hover &,
    [class*='action--'][class*='--btn'][class*='--filled']:focus-visible & {
      // color: rgba($c-white, 0);

      &::before {
        color: $c-dark-grey;
      }

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .header.white:not(.down):not(.menu-open) &::before,
      .homepage .header:not(.menu-open):not(.down) &::before {
        color: $c-white;
      }
    }
  }
}

.action__icon {
  margin-left: $icon-margin;
  transition: $easing 0s;

  .sending &,
  .wysiwyg & {
    display: none;
  }

  [class*='action--'][class*='--facebook'] & {
    color: $c-facebook;
  }

  [class*='action--'][class*='--twitter'] & {
    color: $c-twitter;
  }

  [class*='action--'][class*='--linkedin'] & {
    color: $c-linkedin;
  }

  [class*='action--'][class*='--reverse'] & {
    margin-right: $icon-margin;
    margin-left: 0;
  }

  [class*='action--'][class*='--no-label'] & {
    margin: 0;
  }

  [class*='action--'][class*='--social'] & {
    @include center-xy;

    width: 1.6rem;
    height: 1.6rem;
    pointer-events: none;
    transition: transform 0.2s 0.1s;
  }

  [class*='action--'][class*='--down'] & {
    transform: rotate(90deg);
  }

  [class*='action--'][class*='--btn'][class*='--send'] & {
    margin-right: $icon-margin;
  }

  @include mq(l) {
    [class*='action--'][class*='--arrow']:hover & {
      transform: translateX(math.div($icon-margin, 2));
    }

    [class*='action--'][class*='--down']:hover & {
      transform: rotate(90deg) translateX(math.div($icon-margin, 2));
    }

    [class*='action--'][class*='--leftArrow']:hover & {
      transform: translateX(math.div(-$icon-margin, 2));
    }

    [class*='action--'][class*='--social']:hover & {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }

  @keyframes svg-send {
    50% {
      transform: translate(12px, -12px);
      opacity: 0;
    }

    51% {
      transform: translate(-12px, 12px);
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.action__sending {
  display: none;
  margin-right: math.div($spacing, 2);
  margin-bottom: 4px;

  .sending & {
    display: inline-block;
  }
}

.action__sending__icon {
  display: none;
  width: 2rem;
  height: 2rem;
  border: 5px solid $c-white;
  border-bottom-color: $c-black;
  border-radius: 50%;
  animation: rotation 1s linear infinite;

  .sending & {
    display: inline-block;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
