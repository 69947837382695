
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































.app {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: $dynamic-100vh;
}

.app-view {
  flex: 1;
}
