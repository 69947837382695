
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































































































































.header-trigger {
  @extend %button-nostyle;

  position: relative;
  z-index: 1;
  color: currentColor;

  &:hover {
    color: $c-mustard;
  }
}

.header-trigger__label {
  @extend %ff-alt;
  @include center-y;

  z-index: -1;
  left: 0;
  padding-right: 1rem;
  font-size: 1.5rem;
  clip-path: inset(0 0 0 100%);
  transform: translate(-100%, -50%);

  .open & {
    clip-path: inset(0 0 0 0);
  }

  @include mq($until: m) {
    visibility: hidden;
  }
}

.header-trigger__lines {
  position: relative;
  display: block;
  overflow: hidden;
  width: 2.4rem;
  height: 2.4rem;
}

[class*='line--'] {
  @include center-y;

  display: block;
  width: 100%;
  height: 2px;
  background: currentColor;
  border-radius: 3px;

  // .close & {
  //   .static & {
  //     .homepage & {
  //       background: $c-white;
  //     }
  //   }
  // }
}

.line--top {
  margin-top: -4px;

  .open & {
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.line--middle {
  .open & {
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.line--bottom {
  width: 80%;
  margin-top: 4px;

  .open & {
    transform: translate(50px, -50%);
  }
}
