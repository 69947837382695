
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































































































































































































.footer-overlay {
  @include scrollbars(6px, $c-mustard, $c-white);

  overflow-y: scroll;
  min-height: 70vh;
  max-height: 90vh;
  box-shadow: 0 50px 100px rgba(0, 0, 0, 0.25);

  @include mq(m) {
    display: flex;
    overflow-y: unset;
  }
}

.footer-overlay__side,
.footer-overlay__content {
  position: relative;

  @include mq(m) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: col(5, 12);
  }
}

.footer-overlay__side__info {
  position: relative;
  z-index: 1;
  margin: 0 col(1, 10);

  @include mq(m) {
    margin: 0 col(1, 5);
  }
}

.footer-overlay__side__picture {
  @include image-fit;
}

.footer-overlay__content {
  @include mq(m) {
    @include scrollbars(6px, $c-mustard, $c-white);

    display: block;
    overflow-y: scroll;
    width: col(7, 12);
  }
}

.footer-overlay__content__form,
.footer-overlay__content__result {
  padding-right: col(1, 10);
  padding-left: col(1, 10);

  ::v-deep .form-feedback {
    margin-top: math.div($spacing, 4);
  }

  @include mq(m) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding-right: col(1, 7);
    padding-left: col(1, 7);

    ::v-deep {
      .form-feedback {
        margin-top: math.div($spacing, 2);
      }
    }
  }
}

.footer-overlay__content__form__title {
  @include mq(m) {
    .magazine-stories & {
      margin: 0 0 $spacing * 0.5;
      font-size: 3rem;
    }
  }
}

.footer-overlay__content__form__subtitle {
  margin-bottom: $spacing;
}

.footer-overlay__content__form__input {
  margin-bottom: $spacing;
}

.footer-overlay__content__form__checkbox {
  ::v-deep .form-label {
    &::before,
    .icon {
      top: 0;
      transform: none;
    }
  }
}

.footer-overlay__content__form__validation {
  position: relative;
  margin-top: $spacing * 2;

  ::v-deep .form-feedback {
    position: static;
    margin-top: 1rem;
  }

  @include mq(xs) {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ::v-deep .form-feedback {
      position: static;
      margin: 0 0 0 1rem;
    }
  }
}

.footer-overlay__content__result {
  align-items: center;
}

.icon {
  color: $c-mustard;
}

.form-sending {
  @include center-y;

  left: -4rem;
  opacity: 0;
  animation: sending-appear 0.5s forwards;

  .icon {
    animation: sending-waiting 0.7s 1s infinite ease-in;
  }
}

@keyframes sending-appear {
  0% {
    top: 100%;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
}

@keyframes sending-waiting {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-1rem);
  }

  75% {
    transform: translateY(0.5rem);
  }

  0% {
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
