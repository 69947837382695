
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































::v-deep {
  h1.title {
    max-width: 100%;
  }
}

.expert-single__content {
  ::v-deep {
    .flexible-quote-container {
      border: 0 !important;

      @include mq(l) {
        padding-right: col(1.5, 10);
        padding-left: col(1.5, 10);
        text-align: center;
      }
    }

    .flexible-quote__content {
      @include mq(xl) {
        font-size: 4rem;
      }
    }

    .cta-text__appointment {
      width: 65%;
    }

    .cta-text__title {
      max-width: 8em;
    }
  }
}

.expert-single__gallery {
  width: 100%;
}
