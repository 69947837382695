
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































































































.showroom-appointment {
  margin-top: $spacing * 2;
  border-top: 1px solid $c-mustard;
  border-bottom: 1px solid $c-mustard;

  @include mq(m) {
    display: flex;
  }
}

.showroom-appointment__misc {
  flex-basis: 50%;
  padding: $spacing * 2 $spacing * 2 $spacing * 2 0;

  @include mq(m) {
    border-right: 1px solid $c-mustard;
  }

  @include mq($until: m) {
    border-bottom: 1px solid $c-mustard;
  }
}

.showroom-appointment__misc__field {
  display: flex;

  & + & {
    margin-top: $spacing;
  }

  .icon {
    flex-shrink: 0;
    margin-right: math.div($spacing, 2);
    fill: $c-mustard;
  }
}

.showroom-appointment__misc__field-inner {
  a {
    text-decoration: none;
    transition: color 0.1s $ease-softer;

    &:hover,
    &:focus-visible {
      color: $c-mustard;
    }
  }
}

.showroom-appointment__misc__field__label {
  @extend %fw-bold;

  margin-bottom: math.div($spacing, 2);
}

.showroom-appointment__cta {
  position: relative;
  z-index: 1;
  flex: 1;
}

.showroom-appointment__cta__content {
  @extend %ff-alt;
  @extend %fw-thin;

  display: block;
  padding: $spacing * 2 0;
  cursor: pointer;
  text-decoration: none;

  &::after {
    @include get-all-space;

    content: '';
    z-index: 0;
    background: $c-mustard;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s $ease-out-quad;
  }

  &:hover,
  &:focus {
    color: $c-white;

    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @include mq(m) {
    padding: $spacing * 2;
  }
}

.showroom-appointment__cta__text {
  em {
    @extend %ff-default;
    @extend %fw-normal;
  }
}

.showroom-appointment__cta__title {
  color: $c-mustard;
}

.showroom-appointment__cta__text,
.showroom-appointment__cta__title {
  position: relative;
  z-index: 1;

  .showroom-appointment__cta__content:hover &,
  .showroom-appointment__cta__content:focus & {
    color: $c-white;
  }
}

.showroom-appointment__cta__arrow {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: col(2, 8);
  color: $c-mustard;
  font-size: 3rem;
  transition: transform 0.3s, color 0.2s;

  .icon {
    flex: 1;
    width: 100%;
  }

  .showroom-appointment__cta__content:hover &,
  .showroom-appointment__cta__content:focus & {
    color: $c-white;
    transform: translateX(2em);
  }
}
