
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































































































































.picture {
  position: relative;
}

.picture__container {
  // position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: $c-light-grey;

  .br-default & {
    @extend %border-radius;
  }

  .br-small & {
    @extend %border-radius-small;
  }

  .is-loaded & {
    background: none;
  }
}

.picture__container__fallback {
  position: absolute;
  filter: blur(0.5rem);
}

.picture__container__fallback,
.picture__container__inner {
  will-change: transform;

  .br-default & {
    @extend %border-radius;
  }

  .br-small & {
    @extend %border-radius-small;
  }

  .hover-scale & {
    transition: transform 0.3s $ease-softer;
  }

  .picture.is-cover & {
    @include image-fit;
  }
}

.picture__caption {
  @extend %ff-alt;

  display: flex;
  line-height: 2rem;

  .icon {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -0.2rem;
    color: $c-mustard;
  }

  ::v-deep em {
    @extend %ff-default;
    @extend %fw-light;

    font-size: 1.8rem;
  }

  @include mq(l) {
    margin: 0 0.5rem;
  }
}
