/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-max-combinators */

.vue-file-agent {
  padding: 4rem !important;
  border: 1px dashed $c-gray-light !important;

  .file-preview-wrapper {
    position: relative;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    padding: math.div($spacing, 2) 0;
    border-top: 1px solid $c-light-grey !important; // stylelint-disable-line declaration-no-important;

    &:first-child {
      margin-top: $spacing * 1.5 !important; // stylelint-disable-line declaration-no-important
    }
  }

  &.file-input-wrapper.is-drag-over,
  .is-drag-over &.file-input-wrapper {
    border-color: $c-gray-light !important;
    box-shadow: inset 0 0 20px 1px $c-gray !important;
  }

  &.file-input-wrapper.is-drag-valid.is-drag-over,
  .is-drag-valid.is-drag-over &.file-input-wrapper {
    border-color: $c-gray-light !important;
    box-shadow: inset 0 0 20px 1px $c-gray !important;
  }
}

.form-quote,
.step-project,
.form-partner,
.form-followup {
  .file-preview,
  .file-preview-overlay {
    background: none !important;
  }

  svg:not(.icon):not(.vs__open-indicator),
  .file-size,
  .file-preview::before,
  .file-preview-wrapper::before,
  .thumbnail,
  .help-text,
  .file-preview-new,
  .image-dimension {
    content: none !important;
    display: none !important;
  }
}
