
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































.wysiwyg__content {
  .cpt-wysiwyg & {
    @include mq(m) {
      columns: 2;
      column-gap: col(1, 10);
    }
  }

  .cpt-blog & {
    @include mq(s) {
      padding-right: col(1, 10);
      padding-left: col(1, 10);
    }

    @include mq(l) {
      padding-right: col(2, 10);
      padding-left: col(2, 10);
    }
  }
}
