
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.btn,
[class*='btn--'] {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  &:hover {
    .btn__label {
      color: $c-white;
    }

    .btn__label::after {
      transform: translateX(0%);
    }
  }
}

.btn__label {
  @extend %text-uppercase;
  @extend %fw-bold;

  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  padding: math.div($spacing, 2) $spacing * 1.2;
  color: $c-gray-dark;
  font-size: 1.4rem;
  border: 1px solid $c-mustard;
  border-radius: 20rem;
  transition: color 0.2s ease-out;

  [class*='btn--'][class*='--light'] {
    color: $c-mustard;
    border: 1px solid $c-mustard;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 200%;
    height: 110%;
    background-color: $c-mustard;
    transform: translateX(-100%);
    clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 62% 100%);
    transform-origin: top;
    transition: transform 0.2s ease-out;

    @include mq($until: l) {
      display: none;
    }
  }
}

.btn__icon {
  position: absolute;
  right: -3.1rem;
  width: 6.2rem;
  height: 100%;
  padding: 1rem;
  fill: $c-white;
  background-color: $c-mustard;
  transition: background-color 0.2s ease-out;

  [class*='btn--'][class*='--light'] {
    background-color: $c-mustard;
  }
}
