.wysiwyg,
[class*='wysiwyg--'] {
  @include owl;

  line-height: 1.7;

  h1,
  .h1 {
    @include fluid(
      margin-bottom,
      (
        xxs: 30px,
        m: 50px,
      )
    );
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    margin-bottom: $spacing;
  }

  h2 {
    @extend .h3;

    font-style: normal;
  }

  h3 {
    @extend .h4;
  }

  p,
  .small {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 30px));

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    @include default-margin(0.5);

    padding: 0;
  }

  ol {
    list-style: none;
    counter-reset: li attr(start);

    @for $i from 1 through 100 {
      &[start='#{$i}'] {
        counter-reset: li calc(#{$i} - 1);
      }
    }
  }

  li {
    padding: 0 0 0 5rem;

    @include mq(m) {
      padding-left: 8rem;
    }
  }

  ul li {
    position: relative;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin: 1.5rem;
      background: $c-mustard;
      border-radius: 50%;
    }
  }

  ol li {
    @include numbered-list-item(li);

    position: relative;
    display: block;
    counter-increment: li;

    &::before {
      position: absolute;
      top: -0.5em;
      left: 0;
    }

    @include mq(l) {
      &::before {
        top: -0.3em;
      }
    }
  }

  li + li {
    margin-top: $spacing;
  }

  a:not([class*='action--']) {
    color: $c-mustard;
    text-decoration: none;
    transition: color 1s $ease-80-20;

    &:hover,
    &:focus {
      color: lighten($c-mustard, 20%);
    }
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
        font-size,
        (
          xxs: 9px,
          xl: 11px,
        )
      );

      color: $c-dark-grey;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
        font-size,
        (
          xxs: 15px,
          xl: 18px,
        )
      );

      color: $c-gray-darker;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-gray-light;
    }

    td {
      padding: 3rem 0;
    }
  }

  // Cookiebot output overwriting
  /* stylelint-disable selector-class-pattern */
  .CookieDeclaration {
    .CookieDeclarationType {
      overflow: auto;
      background:
        linear-gradient(to right, $c-white 30%, rgba($c-white, 0)),
        linear-gradient(to right, rgba($c-white, 0), $c-white 70%) 0 100%,
        radial-gradient(farthest-side at 0% 50%, rgba($c-dark-grey, 0.2), transparent),
        radial-gradient(farthest-side at 100% 50%, rgba($c-dark-grey, 0.2), transparent) 0 100%;
      background-attachment: local, local, scroll, scroll;
      background-color: $c-white;
      background-position: 0 0, 100%, 0 0, 100%;
      background-repeat: no-repeat;
      background-size: 4rem 100%, 4rem 100%, 1.5rem 100%, 1.5rem 100%;
      border-color: $c-medium-grey;
    }

    table {
      width: auto;
      min-width: 100%;
      margin: 0;
      table-layout: fixed;
    }

    /* stylelint-disable selector-max-combinators, selector-max-compound-selectors, selector-max-type */
    tbody tr:last-child {
      border-bottom: 0;

      th, td {
        border-bottom: 0;
      }
    }
    /* stylelint-enable selector-max-combinators, selector-max-compound-selectors, selector-max-type */

    /* stylelint-disable no-descending-specificity */
    th,
    td {
      padding-right: $spacing;
      border-color: $c-light-grey;

      // Description column
      &:nth-last-child(3) {
        min-width: 20rem;
      }
    }
    /* stylelint-enable no-descending-specificity */

    @include mq(s) {
      .CookieDeclarationType {
        width: col(10, 8);
        margin-left: col(-1, 8);
      }
    }

    @include mq(l) {
      .CookieDeclarationType {
        width: col(8, 6);
        margin-left: col(-1, 6);
      }
    }
  }
  /* stylelint-enable selector-class-pattern */
}
