// IMPORT GOOGLE FONTS
html {
  font-size: calc(1em * 0.625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  color: $c-gray-darkest;
  font-family: $ff-default;
  font-size: $body-text;
  font-weight: 300;
  line-height: 1.65;

  @include mq(m) {
    font-size: $body-text-m;
  }
}
