
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































.project-card__picture {
  @extend %border-radius;
  @extend %safari-border-fix;

  position: relative;
  display: block;
  overflow: hidden;
  transition: opacity 0.3s $ease-out-cubic;

  img {
    transition: transform 0.3s $ease-out-cubic;
  }

  &:hover,
  &:focus {
    img {
      transform: scale(1.1);
    }
  }

  &.is-loading {
    opacity: 0.7;
  }
}

.project-card__picture__loader {
  @include center-xy;

  display: none;

  .project-card__picture.is-loading & {
    display: block;
  }
}

.project-card__label {
  @extend %fw-bold;

  display: flex;
  line-height: 1.2;
}

.project-card__label__pictures {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding-right: 1rem;
  color: $c-medium-grey;
  border-right: 1px solid $c-medium-grey;

  .icon {
    margin-right: 0.5rem;
  }
}

.project-card__picture__logo {
  @include center-xy;

  z-index: 9;
  padding: $spacing;
  background-color: $c-mustard;
  border-radius: 10rem;

  .icon {
    fill: $c-white;
  }

  @include mq(l) {
    .icon {
      width: 8rem;
      height: auto;
    }
  }
}
