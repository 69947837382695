
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































































































.form-group {
  position: relative;

  .full {
    width: 100%;
  }
}

.form-input {
  box-sizing: border-box;
  width: 100%;
  margin-top: 1rem;
  padding: $spacing * 0.5 $spacing;
  font-weight: 300;
  border: 1px solid $c-gray-light;
  border-radius: 4px;
  -moz-appearance: textfield; // stylelint-disable property-no-vendor-prefix

  &::placeholder {
    color: $c-gray-lighter;
    font-size: 1.5rem;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-internal-autofill-selected {
    background-color: $c-white !important; // stylelint-disable declaration-no-important
    -webkit-box-shadow: 0 0 0 1000px $c-white inset;
    box-shadow: 0 0 0 1000px $c-white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.form-label {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.form-label__required {
  color: $c-mustard;
}

.form-hint {
  margin-top: $spacing * 0.5;
  font-size: 1.3rem;
}
