
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































































































$bp: 'm';

.header {
  --cta-col: 36rem;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  // background: $c-white;
  transition: background 0.2s $ease-softer, box-shadow 0.2s $ease-softer;

  &.down {
    background: $c-white;
    box-shadow: 0 0 10rem 0 rgba($c-black, 0.1);

    .sticky-dropdown & {
      border-bottom: 0.1rem solid $c-gray-lighter;
      box-shadow: none;
    }
  }

  .form-privilege-access &,
  .form-privilege &,
  .partner-landing & {
    display: none;
  }

  &.white:not(.down):not(.menu-open),
  .homepage &:not(.down):not(.menu-open) {
    color: $c-white;
  }

  &.menu-open,
  .homepage &.menu-open {
    color: $c-dark-grey;
    transform: translateY(0);
  }

  // Set default background to white on specific pages
  // Because of double sticky
  .magazine &,
  .anders-wonen-landing & {
    background: $c-white;
  }

  @include mq(s) {
    &.down:not(.menu-open) {
      --header-height: 6rem;
    }
  }

  @include mq(l) {
    .magazine-landing &:not(.down):not(.menu-open) {
      color: $c-white;
    }
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  height: var(--header-height);
  transition: height 0.2s $ease-softer;

  @include mq($until: s) {
    padding-left: 0;
  }
}

.header__logo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  color: $c-white;
  background: $c-mustard;

  svg {
    width: 6rem;
  }

  @include mq(s) {
    svg {
      width: 11rem;
    }
  }

  @include mq($bp) {
    padding: 0;

    svg {
      width: 13rem;
    }
  }
}

.header__logo__label {
  @extend %visually-hidden;
}

.header__logo__icon {
  flex-grow: 1;
  flex-basis: 10rem;
  transition: background 0.2s;

  @include mq(m) {
    .header.align-left:not(.menu-open):not(.down) & {
      flex-grow: inherit;
      background: rgba($c-mustard, 0);

      .notfound & {
        flex-grow: 1;
      }
    }
  }
}

.header__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;

  @include mq($bp) {
    min-width: 30rem;
    transition: min-width 1.5s ease-in;

    .menu-open & {
      min-width: var(--cta-col);
      transition: min-width 1s ease-out;
    }
  }
}

.header__lang {
  display: none;

  @include mq($bp) {
    display: block;
    text-align: right;
  }
}

.header__cta {
  display: block;
  margin: 0 1.5rem 0 1rem;

  // Fix #219
  @include mq($until: s) {
    ::v-deep .action__label {
      font-size: 1.2rem;
    }
  }

  @include mq($until: $bp) {
    [class*='action--'][class*='--btn'] {
      padding: 0.5rem 1rem;
    }
  }

  @include mq($bp) {
    z-index: 10;
    margin: 0 2.5rem;
    transition: opacity 1s;

    .menu-open & {
      opacity: 0;
    }
  }
}
