$border-radius: 0.4rem;

.v-select {
  cursor: pointer;

  svg {
    fill: $c-gray-dark;
  }

  .vs__dropdown-toggle {
    padding: $spacing * 0.5 $spacing;
    background: $c-white;
    border: 1px solid $c-gray-light;
    border-radius: $border-radius;
  }

  .vs__selected-options {
    padding: 0;
  }

  .vs__actions {
    line-height: 1;
  }

  .vs__clear {
    @extend %button-nostyle;

    display: none;
    margin-bottom: 0.3rem;
  }

  .vs__search {
    @extend %fw-bold;

    /* stylelint-disable-next-line declaration-no-important */
    margin-top: 0 !important;
    padding: 0;
    color: $c-gray-dark;
    font-family: $ff-alt;

    &:focus {
      padding: 0;
    }
  }

  &.vs--disabled {
    .vs__search {
      color: $c-white;
    }

    .vs__dropdown-toggle {
      background: $c-gray-dark;
    }

    svg {
      display: none;
    }
  }

  .vs__selected {
    @extend %fw-bold;

    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 !important;
    padding: 0;
    border-radius: 0;

    @include mq($until: m) {
      font-size: 1.6rem;
    }
  }

  .vs__open-indicator {
    transition: transform 0.2s;
  }

  &.vs--open {
    &.up {
      .vs__dropdown-toggle {
        border-right: 1px solid $c-gray-light;
        border-bottom: 1px solid $c-gray-light;
        border-left: 1px solid $c-gray-light;
        border-top-color: transparent;
        border-radius: 0 0 $border-radius $border-radius;
      }
    }

    .vs__open-indicator {
      transform: rotate(-180deg);
    }
  }

  @include mq($until: m) {
    font-size: 1.6rem;
  }
}

.vs__dropdown-menu {
  @extend %list-nostyle;

  z-index: 9;
  padding: $spacing 0;
  border-right: 1px solid $c-gray-light;
  border-bottom: 1px solid $c-gray-light;
  border-left: 1px solid $c-gray-light;

  &.up {
    border-top: 1px solid $c-gray-light;
    border-bottom-width: 0;
    border-radius: 0.4rem 0.4rem 0 0;
  }
}
