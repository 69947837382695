:root {
  // Colors
  --c-primary: #{$c-mustard};
  --c-primary-20: #{rgba($c-mustard, 20%)};
  --c-text: #{$c-dark-grey};
  --c-border: #{$c-light-grey};

  // Layout
  --header-height: 5rem;
  --header-height-down: 5rem;
  --wrapper-padding: 2rem;
  --wrapper-max-width: #{$content-width};

  // Spacings
  --spacing-xs: 8px;
  --spacing-s: 16px;
  --spacing-m: 24px;
  --spacing-l: 40px;

  @include fluid(--spacing-xs, (
    m: 8px,
    xl: 16px,
  ));
  @include fluid(--spacing-s, (
    m: 16px,
    xl: 32px,
  ));
  @include fluid(--spacing-m, (
    m: 24px,
    xl: 48px,
  ));
  @include fluid(--spacing-l, (
    m: 40px,
    xl: 80px,
  ));

  @include mq(s) {
    --header-height: 7.2rem;
    --header-height-down: 6rem;
  }

  @include mq(xs) {
    --wrapper-padding: #{col(1, 24)};
  }

  @include mq(l) {
    --header-height: 8rem;
  }

  @include mq(xl) {
    --wrapper-padding: #{col(2, 24)};
  }

  @include mq(wrapper) {
    --wrapper-padding: #{calc(#{$content-width} / 24 * 2)};
  }
}
