
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































































































@import 'vue-select/src/scss/vue-select.scss';

.form-select {
  position: relative;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }

  &.is-open {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.select-control {
  display: none;
}

.form-select__label {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.real-select {
  @extend %visually-hidden;

  display: none;
}

.form-options {
  position: absolute;
  z-index: 1;
  top: 41px;
  overflow: hidden;
  width: 100%;
  max-height: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
  background: $c-white;
  box-shadow: 0 4px 50px rgba(177, 177, 177, 0.4);
  transition: 0.3s;

  .is-open &,
  &:not(.is-open):focus-within {
    max-height: 500px;
    padding: 15px 0;
  }
}

.form-option {
  display: block;
  width: 100%;
  padding: 0 15px;
  line-height: 30px;
  transition: 0.1s background, opacity 0s;

  &.placeholder {
    color: rgba($c-gray-dark, 0.5);
  }

  &:hover,
  &:focus {
    background: $c-gray-light;
  }
}

.form-select__required {
  color: $c-mustard;
}

.form-select__error {
  margin-top: math.div($spacing, 4);
}

.vs__selected-options {
  @include mq($until: l) {
    font-size: 1.6rem;
  }
}
