
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































/* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
[class*='cta-'] {
  @include default-margin(0.6);

  .cta-text + & {
    margin-top: 0;
  }
}

.cta-link {
  .cta-link + &,
  .cta-experience + & {
    margin-top: 0;

    ::v-deep .flexible-quote-container {
      border-top: 0;
    }
  }

  .cta-post-type + & {
    margin-top: 0;
  }
}

.cta-post-type {
  .cta-link + & {
    margin-top: 0;

    ::v-deep .ctas {
      border-top: 0;
    }
  }
}

.cta-text {
  margin-top: 0;
}
