
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























// .picture-inner {
//   @include image-fit;

//   transition: transform 0.2s ease-out;
// }

.picture {
  width: 100%;

  &.full {
    position: relative;
    overflow: hidden;
    padding-bottom: 65%;

    @include mq(l) {
      padding-bottom: 45%;
    }
  }
}

.picture__container {
  @extend %safari-border-fix;

  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;

  img {
    min-width: 100%;
    transition: transform 0.5s $ease-softer, opacity 0.5s $ease-softer;

    &.before-load {
      opacity: 0;
    }
  }

  .cover & {
    width: 100%;
    height: 100%;

    img {
      @include image-fit;
    }
  }

  .flexible-picture & {
    height: 0;
    padding-bottom: 66.66%;

    img {
      @include image-fit;
    }
  }

  .full & {
    @include center-xy;

    height: 130%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

.picture__caption {
  @extend %ff-alt;

  display: flex;

  .icon {
    flex-shrink: 0;
    height: 3rem;
    color: $c-mustard;
  }

  .full & {
    @extend %visually-hidden;
  }
}
