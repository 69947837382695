
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































.form-group {
  position: relative;
  margin: $spacing * 2 0 $spacing;
  cursor: pointer;

  .form-multiple-select & {
    margin: 0 0 $spacing;
  }
}

.form-checkbox {
  @extend %visually-hidden;
}

.form-label {
  position: relative;
  display: block;
  padding-left: 40px;
  color: $c-gray-dark;
  line-height: 1.3;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid $c-gray-light;
    border-radius: 0.4rem;
    transition: border-color 0.2s;
    transform: translateY(-50%);
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    padding: 2px;
    fill: $c-gray-dark;
    opacity: 0;
    transition: opacity 0.2s;
    transform: translateY(-50%);
  }

  .form-checkbox:checked + & {
    .icon {
      opacity: 1;
    }
  }

  .form-checkbox:focus + & {
    &::before {
      border: 2px solid $c-gray-medium;
    }
  }

  .form__confirmation & {
    @include mq(m) {
      padding-left: 7rem;

      &::before {
        width: 4rem;
        height: 4rem;
      }

      .icon {
        left: 0.7rem;
      }
    }
  }
}

.form-feedback {
  left: 4rem;

  .form__confirmation & {
    @include mq(m) {
      left: 7rem;
    }
  }
}

::v-deep a {
  color: $c-mustard;
}
