
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















.g-intro {
  @include mq(s) {
    max-width: col(8, 10);
  }

  @include mq(m) {
    max-width: col(6, 10);
  }
}
