
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































$bp: 'm';

.header-secondary {
  @extend %list-nostyle;

  margin-top: $spacing;

  @include mq($bp) {
    margin: 0;
  }
}

.header-secondary__item {
  & + & {
    margin: $spacing * 0.5 0 $spacing * 0.25;
  }

  @include mq($from: $bp) {
    & + & {
      margin-top: 0;
    }
  }
}

.header-secondary__item__link {
  @extend %ff-alt;
  @extend %fw-bold;

  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  text-decoration: none;
  transition: color 0.2s $ease-softer;

  &:hover,
  &:focus-visible {
    color: $c-mustard;
  }

  @include mq($from: $bp) {
    line-height: 3rem;
  }
}
