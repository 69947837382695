
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































.footer-cta {
  & + & {
    margin-top: 5rem;
  }

  @include mq(xs) {
    display: flex;
  }
}

.footer-cta__picture {
  position: relative;
  width: 100%;
  margin-bottom: $spacing;

  ::v-deep .picture__container {
    padding-bottom: 110%;
  }

  @include mq(xs) {
    flex-shrink: 0;
    width: 20rem;
    margin: 0 5rem 0 0;

    ::v-deep .picture__container {
      padding: 0;
    }
  }

  @include mq(s) {
    width: 25rem;
  }
}

.footer-cta__title {
  display: flex;
  align-items: center;

  @include mq(xs) {
    display: block;
  }
}

.footer-cta__icon {
  display: block;
  margin-right: $spacing;
  color: $c-mustard;
}

.footer-cta__button {
  white-space: nowrap;
}
