
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























.form-feedback {
  display: flex;
  align-items: center;
  color: $c-dev-error;
  font-size: 12px;

  .icon {
    flex-shrink: 0;
    margin-right: $spacing * 0.5;
  }

  @include mq(s) {
    position: absolute;
    z-index: 1;
  }
}
