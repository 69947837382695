
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































.header-legal {
  margin: 2rem 0;

  @include mq(m) {
    margin: 0;
  }
}

.header-legal__list {
  @extend %list-nostyle;

  ::v-deep a {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.header-legal__item {
  &:not(:last-child) {
    margin-right: math.div($spacing, 2);
  }

  @include mq(m) {
    &:not(:last-child) {
      margin: 0 $spacing 0 0;
    }
  }
}

.header-legal__item__link,
[class*='header-legal__item__link--cookie'] {
  align-items: center;
  height: 100%;

  ::v-deep .action__label {
    padding: 0.5rem 0;
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
