
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































































































.projects-overlay,
.projects-overlay-wrapper {
  display: flex;
  flex-direction: column;
}

.projects-overlay-wrapper {
  flex: 1;
}

.projects-overlay__categories-inner {
  @include mq(s) {
    width: col(8, 10);
  }

  @include mq(m) {
    width: col(5, 10);
  }
}

.projects-overlay__validation {
  position: sticky;
  z-index: 3;
  bottom: 0;
  text-align: center;
  background: $c-white;
  box-shadow: 0 -4px 40px rgba($c-black, 0.1);

  ::v-deep [class*='action--'][class*='--btn'][disabled] {
    background: $c-light-grey;
    border-color: $c-light-grey;
  }

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.projects-overlay__validation__feedback {
  @extend %ff-alt;

  margin-bottom: $spacing;

  span {
    @extend %ff-default;

    min-width: 0.5em;
    color: $c-mustard;
    font-size: 2rem;
  }

  @include mq(m) {
    margin-bottom: 0;
  }
}

::v-deep {
  .project-card__label {
    display: none;
  }
}

.projects-overlay__modal {
  @include get-all-space;

  position: fixed;
  z-index: 2;
  max-height: 100vh;
  background: rgba($c-black, 0.5);
}

.projects-overlay__modal-inner {
  @extend %border-radius-small;
  @include center-xy;

  width: 44rem;
  max-width: 80vw;
  padding: $spacing;
  background: $c-white;
  box-shadow: $card-shadow;
}

.projects-overlay__modal__heading {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
  padding-bottom: $spacing;
  border-bottom: 1px solid $c-light-grey;

  .icon {
    color: $c-mustard;
  }
}

.projects-overlay__modal__heading__title {
  flex: 1;
  margin: 0 $spacing * 0.5;
}

.projects-overlay__modal__heading__button {
  @extend %button-nostyle;

  .icon {
    color: $c-dark-grey;
    transition: color 0.2s $ease-softer;
  }

  &:hover,
  &:focus-visible {
    .icon {
      color: $c-mustard;
    }
  }
}
