
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















.not-found {
  position: relative;
}

.not-found__content {
  text-align: center;
}

.not-found__title {
  @include fluid(
    font-size,
    (
      xxs: 40px,
      xxl: 200px,
    )
  );

  color: $c-mustard;
  line-height: 1.1;
}

.not-found__baseline {
  line-height: 1.2;

  @include mq(m) {
    padding: 0 col(2, 10);
  }
}

.not-found__picture {
  @include get-all-space;

  z-index: -1;
}
