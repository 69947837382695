
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































.footer-links {
  @include default-margin(0.5);

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq(m) {
    display: grid;
    grid-template: repeat(2, 1fr) / 30% 1fr;
    grid-gap: $spacing;
    place-items: center start;
  }

  @include mq(xl) {
    grid-template-rows: 1fr;
    grid-template-columns: auto auto 1fr auto;
    // gap: 0 $spacing * 2.5;
    grid-gap: 0;
  }
}

.link-label {
  @extend %visually-hidden;
}

.footer-links__logo {
  width: 100%;
  margin-bottom: $spacing;
  color: $c-mustard;
  transition: color 0.1s;

  &:hover,
  &:focus {
    color: lighten($c-mustard, 20%);
  }

  @include mq(m) {
    margin: 0;
  }

  @include mq(xl) {
    width: auto;
  }
}

.footer-links__social {
  width: 100%;

  @include mq(m) {
    grid-row: 2/3;
    grid-column: 1/2;
  }

  @include mq(xl) {
    grid-row: 1/2;
    grid-column: 2/3;
    width: auto;
    margin-top: 0;
  }
}

.footer-links__legals {
  @extend %list-nostyle;

  margin-top: $spacing;

  @include mq(m) {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    place-self: center end;
  }
}

.footer-links__legals__link,
[class*='footer-links__legals__link--'] {
  display: block;
  color: $c-medium-grey;

  &,
  &[class*='--cookies'] {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 0;
  }

  @include mq(m) {
    &,
    &[class*='--cookies'] {
      padding-left: $spacing * 0.5;
    }
  }

  @include mq(xl) {
    &,
    &[class*='--cookies'] {
      padding-right: $spacing;
      padding-left: $spacing;
    }
  }
}

.footer-links__link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $c-medium-grey;
  text-align: right;
  transition: color 0.1s;

  &:hover,
  &:focus {
    color: $c-mustard;
  }

  @include mq(m) {
    place-self: center end;
  }
}
