//Avoid typekit FOUT
html {
  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

// NO SCROLL
.no-scroll,
.popup-active {
  overflow: hidden;
  height: 100vh;

  @media (hover: hover) {
    padding-right: 15px;
  }
}

// VARIOUS
hr {
  height: 1px;
  background-color: $c-gray-darker;
  border: 0;
}

.is-hidden {
  visibility: hidden;
}

.icon {
  fill: currentColor;
  vertical-align: middle;
}

// Visually hide stuff while keeping it in the accessible tree
.visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  white-space: nowrap;
}
