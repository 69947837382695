
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































.footer {
  .form-privilege-access &,
  .form-privilege &,
  .partner-landing & {
    display: none;
  }
}

.footer__ctas {
  border-top: 1px solid $c-light-grey;
  border-bottom: 1px solid $c-light-grey;

  .form-quote &,
  .form-showrooms & {
    display: none;
  }

  @include mq(m) {
    display: flex;
  }
}

.footer-cta {
  & + & {
    margin-top: 5rem;
  }

  @include mq(m) {
    margin-right: col(0.5, 10);

    & + & {
      margin: 0;
    }
  }

  @include mq(l) {
    flex: 1;
    margin-right: col(1, 10);
  }
}
