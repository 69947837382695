// Before wrapper mq = 24 available columns
// After wrapper mq = 22 available columns
// See _custom-properties.scss for padding progression
.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: var(--wrapper-max-width);
  padding-right: var(--wrapper-padding);
  padding-left: var(--wrapper-padding);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
  }
}
