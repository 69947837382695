
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.showroom-info {
  padding: $spacing * 0.8;
  border: 0.1rem $c-mustard solid;
  border-radius: 0.8rem;

  @include mq(l) {
    display: flex;
    align-items: baseline;
    gap: $spacing * 0.8;
  }
}

.showroom-info__title {
  @extend %fw-bold;
  @extend %ff-alt;

  flex-shrink: 0;

  .icon {
    display: inline-block;
    margin-right: $spacing * 0.4;
    color: $c-mustard;
    vertical-align: bottom;
  }
}
