
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































.socials {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
}

.socials__link {
  display: block;
  padding: 0.2rem 0.5rem;
  color: $c-medium-grey;
  transition: color 0.1s;

  &:hover,
  &:focus {
    color: $c-mustard;
  }

  .socials__item:first-child & {
    padding-left: 0;
  }

  .socials__item:last-child & {
    padding-right: 0;
  }

  @include mq(m) {
    padding: 0.2rem 0.75rem;
  }
}

.link-label {
  @extend %visually-hidden;
}
