
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































.crumbs {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  color: $c-mustard;
}

.crumbs__item {
  display: none;

  &:nth-last-child(2) {
    display: flex;
  }
}

::v-deep {
  em {
    color: currentColor;
    font-style: normal;
  }
}

@include mq($until: 'm') {
  .crumbs__item:not(:nth-last-child(2)) {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
  }
}

@include mq('m') {
  .crumbs__item {
    display: flex;

    &:not(:last-child) {
      margin-right: math.div($spacing, 2);

      &::after {
        content: '/';
        margin-left: math.div($spacing, 2);
        color: $c-gray-light;
      }
    }

    ::v-deep .action__icon {
      display: none;
    }
  }
}
