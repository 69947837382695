
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































.header-lang {
  @extend %ff-alt;
  @extend %text-uppercase;

  position: relative;
  font-size: 1.5rem;
}

.header-lang__current {
  @extend %button-nostyle;
  @extend %text-uppercase;

  padding: 1rem 0 0.5rem;
  color: currentColor;
  white-space: nowrap;

  .icon {
    margin-left: 1rem;
    transition: transform 0.5s;
  }

  &:focus-visible {
    .icon {
      transform: rotate(90deg);
    }
  }

  .is-open & {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.header-lang__list {
  @extend %list-nostyle;
  @include center-x;

  top: 4rem;
  overflow: hidden;
  max-height: 0;
  text-align: left;
  background: $c-white;
  border-radius: 0.8rem;
  box-shadow: $card-shadow;
  opacity: 0;
  transition: max-height 0.25s, opacity 0s 0.25s;

  .is-open & {
    max-height: 7rem;
    opacity: 1;
    transition: max-height 0.5s;
  }
}

.header-lang__item {
  width: 100%;

  a {
    display: block;
    width: 6rem;
    padding: $spacing * 0.4 $spacing * 0.75 $spacing * 0.125;
    color: $c-dark-grey;
    text-decoration: none;
    transition: color 0.1s;

    &:hover,
    &:focus-visible {
      color: $c-mustard;
    }
  }

  & + & {
    a {
      padding-top: $spacing * 0.125;
      padding-bottom: $spacing * 0.4;
    }
  }
}
