
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























.g-checklist {
  @extend %list-nostyle;
}

.g-checklist__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & + & {
    margin-top: math.div($spacing, 2);
  }
}

.icon {
  flex-shrink: 0;
  margin-right: math.div($spacing, 2);
  color: $c-mustard;
}
