
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































.prices {
  @extend %border-radius-small;

  padding: $spacing;
  border: 1px solid $c-light-grey;
}

.prices--large {
  @include default-margin(0.5);

  padding: $spacing 0;
  border: 0;
  border-top: 1px solid $c-light-grey;
  border-radius: 0;

  &:last-child:not(:first-child) {
    padding-bottom: 0;
  }

  @include mq(m) {
    display: flex;
    padding: $spacing * 2 0;
  }
}

.prices__label {
  @extend %ff-alt;

  line-height: 1.3;

  .icon {
    margin-right: math.div($spacing, 2);
  }

  .prices--large & {
    .icon {
      display: block;
      width: 3rem;
      height: 3rem;
      margin: 0 0 math.div($spacing, 2);
    }

    @include mq(m) {
      width: col(4, 10);
      margin-right: col(1, 10);
      padding-right: col(1, 10);

      .realizations & {
        margin-right: col(0.5, 10);
      }
    }

    @include mq(l) {
      font-size: 1.8rem;
    }

    @include mq(xl) {
      font-size: 1.8rem;
    }
  }
}

.prices__content {
  margin-top: math.div($spacing, 2);

  @include mq(m) {
    .prices--large & {
      flex: 1;
      margin-top: 0;
    }
  }
}

.prices__content__item {
  & + & {
    margin-top: math.div($spacing, 2);
    padding-top: math.div($spacing, 2);
    border-top: 1px solid $c-light-grey;
  }

  ::v-deep em {
    color: $c-mustard;
    font-size: 1.25em;
    line-height: 0.8;
  }

  .prices--large & + & {
    margin-top: $spacing;
    padding-top: 0;
    border-top: 0;
  }
}

.prices__content__details {
  @extend %list-nostyle;
}

[class*='prices__content__details__item--'] {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  font-family: $ff-alt;

  /* stylelint-disable-next-line no-descending-specificity */
  .icon {
    color: $c-mustard;
  }

  ::v-deep em {
    font-family: $ff-default;
  }

  &[class*='--price'] {
    font-size: 2rem;
  }

  & + & {
    margin-top: $spacing * 0.6;
  }
}

.prices__content__text,
.prices__content__link {
  margin-top: $spacing;
}
