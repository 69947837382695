
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































































::v-deep h1.title {
  @include mq(l) {
    max-width: 80%;
  }
}

.project-single__heading {
  position: relative;
}

.project-single__heading__title {
  ::v-deep em {
    color: $c-dark-grey;
  }
}

.project-single__heading__reference {
  @extend %text-uppercase;

  position: absolute;
  bottom: $spacing * -2;
  color: $c-white;

  span + span {
    margin-left: $spacing;
  }
}

.project-single__content__picture {
  margin-bottom: $spacing;

  @include mq(xl) {
    margin-bottom: $spacing * 2;
  }
}

.project-single__content__masonry {
  ::v-deep .picture {
    overflow: hidden;
  }

  @include mq(l) {
    --masonry-sizer: 50%;
  }

  @include mq(l) {
    --masonry-spacing: #{$spacing};
  }
}

.wysiwyg,
.project-single__ctas {
  @include default-margin;
}

.project-single__prices {
  .project-single__before-after + & {
    .prices--large {
      border-top: 0;
    }
  }
}
