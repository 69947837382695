
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















.loader {
  --loader-size: 4rem;
  --loader-primary-color: #{$c-lighter-grey};
  --loader-secondary-color: #{$c-mustard};
  --loader-thickness: calc(var(--loader-size) * 0.1);

  position: relative;
  width: var(--loader-size);
  height: var(--loader-size);
  border: var(--loader-thickness) solid var(--loader-primary-color);
  border-radius: 50%;

  &::before {
    content: '';
    display: block;
    width: calc(100% - var(--loader-thickness));
    height: calc(100% - var(--loader-thickness));
    margin: calc(var(--loader-thickness) * 0.5);
    border: var(--loader-thickness) solid transparent;
    border-bottom-color: var(--loader-secondary-color);
    border-radius: 50%;
    animation: loader 0.8s linear infinite;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
