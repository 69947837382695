
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































































































































































































































.project-grid {
  transition: opacity 0.5s 0.5s;

  &.is-loading {
    opacity: 0;
    transition: opacity 0.5s 0s;
  }
}

.masonry-grid {
  @extend %list-nostyle;

  margin: math.div(-$spacing, 2);

  @include mq(m) {
    margin: -$spacing;
  }
}

.masonry-grid__item {
  .project-card,
  .picture {
    padding: math.div($spacing, 2);

    @include mq(m) {
      padding: $spacing;
    }
  }
}

.masonry-grid__item,
.grid-sizer {
  width: 100%;

  ::v-deep img {
    transition: 0.3s $ease-out-cubic;
    transition-property: opacity, transform;
  }

  &:hover,
  &:focus-within {
    ::v-deep img {
      transform: scale(1.1);
    }
  }

  @include mq('xs') {
    width: col(5, 10);
  }

  @include mq('l') {
    width: calc(100% / 3);

    .project-single &,
    .projects-overlay & {
      width: col(5, 10);
    }
  }
}

.masonry-grid__item__picture {
  position: relative;

  &.inspiration {
    ::v-deep {
      .picture__caption {
        display: none;
      }
    }
  }
}

.masonry-grid__item__button {
  @extend %button-nostyle;
  @include get-all-space;
}

.masonry-grid__item__button__label {
  @extend %ff-alt;

  position: absolute;
  z-index: 1;
  bottom: 4rem;
  left: 4rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  font-size: 1.5rem;
  background: $c-white;
  border: none;
  border-radius: 20px;
  transition: background 0.5s $ease-80-20;

  .icon {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .selected & {
    color: $c-white;
    background: $c-mustard;
  }
}

.masonry-grid__item__button__label__icons {
  position: relative;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  margin-right: math.div($spacing, 4);
  cursor: pointer;
  transition: transform 0.5s $ease-80-20;

  .icon {
    @include center-xy;

    transition: color 0.5s $ease-80-20;
  }

  .check {
    color: $c-white;
    opacity: 0;
    transition: opacity 0.5s $ease-80-20;
  }

  .selected & {
    transform: none;

    .plus {
      opacity: 0;
    }

    .check {
      opacity: 1;
    }
  }

  .masonry-grid__item__button:hover &,
  .masonry-grid__item__button:focus & {
    .plus {
      color: $c-mustard;
    }
  }
}

.slide-up-enter-active {
  transition: opacity 1s ease, transform 1s ease;
}

.slide-up-enter {
  transform: translateY(10px);
  opacity: 0;
}

.project-grid__loadmore {
  @extend %text-center;
}
