
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































































































































































































































































@import '~vlitejs/dist/vlite.css';

.g-video {
  position: relative;
  z-index: 0;
  aspect-ratio: 16/9;

  ::v-deep .v-vlite {
    /* stylelint-disable-next-line custom-property-pattern */
    --vlite-colorPrimary: #{$c-mustard};

    @extend %ff-alt;
    @extend %border-radius-small;

    border-radius: 10px;
  }
}

.video__poster {
  @extend %border-radius-small;
  @include get-all-space;

  z-index: 10;
  overflow: hidden;
  transition: opacity 0.2s;
}

.video__poster__play {
  --play-width: 5rem;

  @extend %button-nostyle;
  @include center-xy;

  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--play-width);
  height: var(--play-width);
  color: $c-white;
  text-align: center;

  &::after {
    @include get-all-space;

    content: '';
    z-index: -1;
    background: $c-mustard;
    border-radius: 50%;
    transition: 0.2s ease-out;
  }

  @include mq(xs) {
    --play-width: 7rem;
  }

  @include mq(m) {
    --play-width: 20rem;

    padding: 0 $spacing $spacing;

    &:hover::after {
      transform: scale(1.05);
    }
  }
}

.video__poster__play__icon {
  --icon-width: 3rem;

  @include center-xy;

  display: block;
  width: var(--icon-width);
  height: var(--icon-width);
  transform: translate(calc(-50% + 2px), -50%);

  @include mq(xs) {
    --icon-width: 4rem;
  }

  @include mq(s) {
    position: relative;
    top: 0;
    left: 2px;
    padding: 0;
    background: 0;
    transform: none;
  }

  @include mq(m) {
    --icon-width: 6rem;
  }
}

.video__poster__play__label {
  @extend %ff-alt;
  @extend %fw-medium;

  font-size: 2.5rem;
  line-height: 1;

  @include mq($until: m) {
    display: none;
  }
}

.video__poster__loop {
  @include image-fit;
}

.video__poster__picture {
  @include get-all-space;

  img {
    @include image-fit;
  }
}

.video__player {
  border-radius: 10px;
}
