a {
  color: $c-gray-dark;

  &:hover {
    text-decoration: none;
  }
}

.htmltext a {
  @extend %ff-alt;

  font-size: 1.4rem;
  transition: color 0.1s ease-out;

  &:hover {
    color: $c-mustard;
  }

  @include mq(s) {
    font-size: 1.6rem;
  }
}

[class*='--yellow'] em {
  color: $c-mustard;
}

h1,
.h1,
[class*='h1--'],
h2,
.h2,
[class*='h2--'],
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  @extend %ff-alt;

  color: $c-gray-dark;

  em,
  i {
    @extend %ff-default;
    @extend %fw-thin;

    font-style: italic;
    line-height: 1.1;

    [class*='--yellow'] & {
      color: $c-mustard;
    }
  }
}

h1,
.h1,
[class*='h1--'] {
  @extend %ff-alt;
  @extend %fw-bold;

  font-size: 3.6rem;
  line-height: 1;

  @include mq($until: m) {
    &[class*='--long'] {
      font-size: 2.6rem;
      line-height: 1.2;
    }
  }

  @include mq(m) {
    font-size: 4rem;
  }

  @include mq(xxl) {
    font-size: 4.5rem;
  }
}

h2,
.h2,
[class*='h2--'] {
  @extend %ff-alt;
  @extend %fw-bold;

  font-size: 2.8rem;
  font-style: normal;
  line-height: 1;

  &[class*='--small'] {
    font-size: 2.2rem;
  }

  &[class*='--light'] {
    @extend %ff-default;
    @extend %fw-light;

    font-style: italic;
  }

  em,
  i {
    color: $c-mustard;
  }

  @include mq(l) {
    font-size: 3.6rem;
    line-height: 1.2;

    &[class*='--small'] {
      font-size: 3.2rem;
    }
  }

  @include mq(xxl) {
    font-size: 4.8rem;

    &[class*='--small'] {
      font-size: 3.2rem;
    }
  }
}

h3,
.h3,
[class*='h3--'] {
  @extend %ff-alt;
  @extend %fw-bold;

  font-size: 2rem;
  line-height: 1.2;

  em,
  i {
    color: $c-mustard;
  }

  @include mq(l) {
    font-size: 3.6rem;
  }
}

h4,
.h4 {
  font-size: 1.8rem;
  font-style: normal;
  line-height: 1.3;

  @include mq(l) {
    font-size: 2rem;
  }

  @include mq(xxl) {
    font-size: 2.4rem;
  }
}

body {
  @extend %fw-normal;
  @extend %ff-default;

  font-size: 1.5rem;
  line-height: 1.4;

  @include mq(l) {
    font-size: 1.8rem;
  }
}

.quote,
[class*='quote--'] {
  @extend %fw-normal;
  @extend %ff-alt;

  font-size: 2.2rem;
  line-height: 1.4;

  strong {
    @extend %fw-bold;
  }

  em,
  i {
    @extend .g-yellow-italic;
  }

  &[class*='--light'] {
    color: $c-gray;
  }

  @include mq(l) {
    font-size: 2.8rem;
  }

  @include mq(xxl) {
    font-size: 3.2rem;
  }
}

.lead {
  @extend %fw-normal;
  @extend %ff-default;

  font-size: 1.8rem;
  line-height: 1.6;

  strong {
    @extend %ff-alt;
    @extend %fw-bold;
  }

  @include mq(l) {
    font-size: 2.4rem;
  }
}

.small {
  @extend %fw-normal;
  @extend %ff-default;

  font-size: 1.4rem;
  line-height: 1.7;

  @include mq(l) {
    font-size: 1.5rem;
  }
}

.g-yellow-italic {
  @extend %ff-default;
  @extend %fw-light;

  color: $c-mustard;
  font-style: italic;
}

.g-label,
[class*='g-label--'] {
  @extend %fw-bold;

  color: $c-dark-grey;
  font-size: 1.3rem;
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  &[class*='--yellow'],
  em {
    color: $c-mustard;
    font-style: normal;
  }
}

.g-intro {
  @extend %fw-normal;
  @extend %ff-default;

  font-size: 1.8rem;
  line-height: 1.5;

  strong {
    @extend %fw-bold;
  }
}

.g-category {
  @extend %button-nostyle;
  @extend .g-label;

  position: relative;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.7rem;
  color: $c-light-grey;
  font-size: 1.1rem;
  line-height: 1.2rem;
  text-decoration: none;
  border-radius: 4px;
  transition: color 0.5s $ease-80-20;

  &::after {
    @include get-all-space;

    content: '';
    z-index: -1;
    display: block;
    border: 1px solid $c-light-grey;
    border-radius: 4px;
    transition: 1s $ease-80-20;
  }

  &:hover,
  &:focus {
    color: $c-mustard;

    &::after {
      border-color: $c-mustard;
    }
  }

  &.is-selected {
    color: $c-white;

    &::after {
      background: $c-mustard;
      border-color: $c-mustard;
      transform: scale(1.05, 1.2);
    }
  }
}

/**
 * Shared MoodBoard Builder stuff
 */
.mbb-overview__title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 25px, xl: 40px));
}

.mbb-overview__lead {
  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xl: 25px));

  font-family: $ff-default;
  font-style: italic;
  line-height: 1;
}
