
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































































.video-popup {
  @include get-all-space;

  position: fixed;
  z-index: 1000;
  background: $c-white;
}

.video-popup__button {
  position: absolute;
  top: $spacing;
  right: $spacing * 1.5;
}

.video-popup__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5rem 0;
}

.video-popup__content__video {
  width: 100%;

  ::v-deep .v-vlite {
    border-radius: 0;
  }

  @include mq(m) {
    flex: 1;

    ::v-deep .g-video {
      width: auto;
      height: 100%;
      aspect-ratio: unset;
    }

    ::v-deep .v-vlite {
      height: 100%;
      aspect-ratio: unset;
    }
  }
}

.video-popup__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing $spacing * 2 5vh;
}

.video-popup__controls__button {
  @extend %button-nostyle;
  @extend %ff-alt;

  display: flex;
  align-items: center;
  width: 4rem;
  height: 4rem;
  color: $c-mustard;
  transition: color 0.1s;

  &:hover {
    color: lighten($c-mustard, 20%);
  }
}

.video-popup__controls__button__label {
  @extend %visually-hidden;
}

.pause-line {
  display: inline-block;
  width: 4px;
  height: 20px;
  margin: 0 2px 1px;
  background: currentColor;
}
