
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































































































































































/* stylelint-disable selector-class-pattern */
$item-height: (
  1: 100%,
  2: 90%,
  3: 80%,
  4: 100%,
  5: 75%,
);

.flexible-gallery {
  --gallery-height: 40rem;
  --img-translate-x: 0;

  position: relative;
  overflow: hidden;
  margin-top: 2rem;
  padding-bottom: 5rem;

  &.cpt-miniGallery {
    @include fluid(
      --gallery-height,
      (
        xxs: 450px,
        xl: 560px,
      )
    );
  }

  &.has-intro {
    padding-bottom: 0;
  }

  @include mq(l) {
    --gallery-height: 60rem;
  }

  @include mq(xxl) {
    --gallery-height: 30vw;

    &.cpt-galleryWlink {
      --gallery-height: 40vw;
    }
  }
}

.flexible-gallery__title {
  @extend %text-center;

  .jobs & {
    text-align: left;
  }
}

.flexible-gallery__items {
  @extend %list-nostyle;
  @extend %text-center;

  display: flex;
  align-items: flex-start;
  min-width: fit-content;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
}

// Item
.flexible-gallery__item {
  --item-picture-ratio: 1; // set with JS
  --item-picture-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: fit-content;
  height: var(--gallery-height);
  margin-left: $spacing;
  will-change: transform;

  ::v-deep [data-gallery-image] {
    transform: scale(1.2) translateX(var(--img-translate-x));
    will-change: transform;
    transition: none;
  }

  .cpt-miniGallery & {
    justify-content: flex-start;
    width: 24rem;

    &:nth-child(4n + 1) {
      justify-content: center;
    }

    &:nth-child(4n + 2) {
      justify-content: flex-end;
    }

    &:nth-child(4n + 3) {
      justify-content: center;
      padding-top: $spacing * 2;
    }
  }

  .cpt-galleryWlink & {
    height: auto;
    justify-content: flex-end;
  }

  @include mq(l) {
    margin-left: $spacing * 2;
  }

  @each $i, $value in $item-height {
    &:nth-child(5n + #{$i}) {
      --item-picture-height: #{$value};
    }
  }
}

.flexible-gallery__item__picture {
  @extend %border-radius;

  position: relative;
  overflow: hidden;
  width: fit-content;
  height: var(--item-picture-height);

  .cpt-miniGallery & {
    width: 100%;
    height: 0;
    padding-bottom: 140%;
  }

  .cpt-galleryWlink & {
    display: flex;
    align-items: flex-end;
    flex-shrink: 1;
    height: var(--gallery-height);
    border-radius: 0;
    clip-path: inset(calc(100% - var(--item-picture-height)) 0% 0% round 2rem);
  }
}

.flexible-gallery__item__picture__inner {
  width: auto;
  max-width: none;
  height: 100%;

  .cpt-miniGallery & {
    @extend %border-radius;
    @include image-fit;

    transform: none !important; // stylelint-disable-line
  }

  .cpt-galleryWlink & {
    height: var(--item-picture-height);
  }
}

.flexible-gallery__item__picture__inner__icon {
  position: absolute;
  z-index: 1;
  right: $spacing;
  bottom: $spacing;
  padding: $spacing * 0.25;
  background-color: $c-mustard;
  fill: $c-white;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  .flexible-gallery__item:hover & {
    opacity: 1;
  }
}

.flexible-gallery__item__picture__legend {
  display: flex;
  gap: $spacing * 0.25;
  width: min-content;
  min-width: 100%;
  margin-top: $spacing;
  line-height: 1.2;
  text-align: left;
  text-decoration: none;

  .icon {
    position: relative;
    top: -0.1rem;
    flex-shrink: 0;
    color: $c-mustard;
  }

  .cpt-miniGallery & {
    gap: 0;
    margin-top: $spacing * 0.25;
    transform: none;

    &::before {
      content: none;
    }
  }

  @include mq($until: l) {
    font-size: 1.3rem;
  }
}

// Intro
.flexible-gallery__intro {
  @include mq(m) {
    max-width: 80rem !important; // stylelint-disable-line
  }
}

// Categories
.flexible-gallery__categories {
  @include scrollbars(0, $c-light-grey);

  overflow-x: scroll;
  padding-top: $spacing * 3;

  &::-webkit-scrollbar {
    height: 0.6rem;
  }

  @include mq(m) {
    padding-top: $spacing * 4;
  }

  @include mq(l) {
    padding-top: $spacing * 5;
  }

  @include mq(xl) {
    // remove wrapper
    /* stylelint-disable declaration-no-important */
    max-width: none !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    /* stylelint-enable declaration-no-important */
  }
}

.flexible-gallery__categories__list {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;
  min-width: fit-content;
}

.flexible-gallery__category {
  @extend %fw-bold;
  @extend %text-center;

  display: block;
  width: 20rem;
  padding: 0 $spacing * 0.5 $spacing;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-decoration: none;

  .flexible-gallery__category-outer:first-child & {
    padding-left: 0;
  }

  .flexible-gallery__category-outer:last-child & {
    padding-right: 0;
  }
}

.flexible-gallery__category__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 14rem;
  margin-bottom: $spacing * 1.25;
  border-radius: 3rem;
  box-shadow: $card-shadow;

  /* stylelint-disable-next-line no-descending-specificity */
  img {
    @include image-fit;

    transition: transform 0.3s $ease-softer;

    @media (pointer: fine) {
      .flexible-gallery__category:hover &,
      .flexible-gallery__category:focus & {
        transform: scale(1.1);
      }
    }
  }
}

// Link
.flexible-gallery__link {
  @include default-margin(0.5);

  text-align: center;
}
