
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































































































































































.showroom-slider {
  overflow: hidden;
}

.showroom-slider__title {
  border-top: 1px solid $c-light-grey;
}

.showroom-slider-container {
  @extend %border-radius-small;

  position: relative;
  overflow: hidden;
  aspect-ratio: 3/2;
}

.showroom-slider__items {
  @extend %list-nostyle;
  @include center-y;

  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.showroom-slider__item {
  display: block;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.showroom-slider__item__picture {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.picture {
    width: 100%;
  }

  ::v-deep .picture__container {
    flex-grow: 1;
    height: 100px;
    padding-bottom: 0;
  }

  ::v-deep img,
  video {
    @include image-fit(cover);
  }

  // @include mq(l) {
  //   max-width: 70vw;

  //   &.picture {
  //     width: 86rem;
  //   }
  // }
}

.showroom-slider__controls {
  @include default-margin(0.5);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq(xs) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

[class*='showroom-slider__controls__item--'] {
  padding: math.div($spacing, 2) $spacing * 1.5 !important;

  &[class*='--next'] {
    margin-left: $spacing * 2;
  }

  @include mq(xs) {
    & + & {
      margin-left: $spacing;
    }
  }
}

.showroom-slider__controls__item--link {
  display: inline-block;
  flex-basis: 100%;
  margin-top: $spacing;
  text-align: center;

  @include mq(xs) {
    flex-basis: auto;
    margin-top: 0;
  }
}

.showroom-slider__label__pictures {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding-right: 1rem;
  color: $c-medium-grey;
  border-right: 1px solid $c-medium-grey;

  .icon {
    margin-right: 0.5rem;
  }
}
